import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.scss";
import NavbarDropdown from "./navbarComponents/NavbarDropdown";
import NavbarEntry from "./navbarComponents/NavbarEntry";

import Argo_Logo from "../assets/Argo_Logo.svg";
import TokenIcon from "@mui/icons-material/Token";
import GroupsIcon from "@mui/icons-material/Groups";
import XIcon from '@mui/icons-material/X';
import TwitterIcon from "@mui/icons-material/Twitter"; // Import Twitter icon
import AboutIcon from "@mui/icons-material/Info"; // Example icon for "About"
import AVSIcon from '@mui/icons-material/Language';
import ScienceIcon from '@mui/icons-material/Science';

const Navbar = () => {
  const location = useLocation(); // From react-router-dom

  // Determine if the path is the active path
  const isActive = (path) => location.pathname === path;

  // The entries to use for the AVS section
  const avs_entries = [
    {
      name: "Overview",
      link: "/avs/overview"
    },
    // {
    //   name: "Risk Analysis",
    //   link: "/avs/risk-analysis"
    // },
    {
      name: "AVS Intel (Soon)",
      link: "",
    }
  ];

  return (
    <div className="navbar">
      <div className="pages">

        <Link to="/">
          <img src={Argo_Logo} style={{ width: "100px" }} alt="Argo Logo" />
        </Link>

        <Link to="/">
          <span className={isActive("/") ? "current" : "other"}>
            <div className="icon-text-container">
              <AVSIcon className="menu-icon" />
              AVS
            </div>
          </span>
        </Link>
        
        <Link to="/lrt">
          <span className={isActive("/lrt") ? "current" : "other"}>
            <div className="icon-text-container">
              <TokenIcon className="menu-icon" />
              LRT
            </div>
          </span>
        </Link>

      
        {/* <NavbarEntry icon={<TerminalIcon />} name={"LR Terminal"} link={"/"}/> */}
        
        {/* <NavbarDropdown icon={
          <AVSIcon 
            style={{
              width:  "0.65em",
              height: "0.65em",
              }}/>} 
            title={"AVS"} 
            entries={avs_entries}
          /> */}
          

        <Link to="https://paragraph.xyz/@argo"  target="_blank">
          <span className={isActive("/guild") ? "current" : "other"}>
            <div className="icon-text-container">
              <ScienceIcon className="menu-icon"/>
              Research
            </div>
          </span>
        </Link>
        
        {/* NOTE: Uncomment to re-add documentation */}
        {/* <Link to="https://argo-network.gitbook.io/argo-network/" target="_blank">
          <span className={isActive("/about") ? "current" : "other"}>
            <div className="icon-text-container">
              <AboutIcon className="menu-icon" />
              Documentation
            </div>
          </span>
        </Link> */}

      </div>

      <div className="logos">
        <div className="social-media">
          <a
            href="https://twitter.com/Argo_XYZ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <XIcon className="icon" />
          </a>
        </div>
        <p>
          Designed by{" "}
          <a
            href="https://www.anthias.xyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anthias Labs
          </a>
        </p>
      </div>
    </div>
  );
};

export default Navbar;
