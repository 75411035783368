import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Legend,
  ResponsiveContainer,
} from "recharts";
import supabase from "../../config/supabaseClient";
import LRTChartCommon from "./lrtChartCommon";

const MarketChart = ({ title }) => {
  const [data, setData] = useState([]);

  useEffect(() => {

    const fetchData = async () => {
        let { data: tokensData, error } = await supabase
          .from('tokens')
          .select('protocol_name, market_share')

        
        if (error) {
        console.error("Error fetching tokens data:", error);
        return;
      }
      const sortedData = tokensData.sort((a, b) => {
        const nameA = a.protocol_name;
        const nameB = b.protocol_name;

        return nameA.localeCompare(nameB);
      });
      
      setData(sortedData);
    };

    fetchData();
  }, []);

  // Get colors from common source
  const chartColors = LRTChartCommon.colors; 
  const nameMap = LRTChartCommon.nameMap;
  const colorMap = LRTChartCommon.colorMap;

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent * 100 < 10) {
      return null; // Do not render anything if the percentage is less than 10
    }

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="12"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // Customized so that we can have colored entries
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const entry = payload[0];
      const percentValue = `${(entry.value * 100).toFixed(2)}%`;      
  
      return (
        <div className="custom-tooltip" style={{
          padding: "1em 5px",
          fontSize: "10px",
          backgroundColor: "rgba(0, 0, 0)",
        }}>
          <p 
            style={{
              color: colorMap[entry.name],
            }}
            >
            {`${nameMap[entry.name] || entry.name} : ${percentValue}`}
          </p>
        </div>
      );
    }
  };

  return (
    <div>
      <p
        style={{
          textAlign: "left",
          color: "white",
          marginBottom: "2vh",
          fontSize: "14px",
        }}
      >
        {title}
      </p>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            data={data}
            labelLine={false}
            outerRadius={"100%"}
            dataKey="market_share"
            nameKey="protocol_name"
            label={renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                stroke="none"
                fill={chartColors[index % chartColors.length]}
              />
            ))} 
          </Pie>
          <Tooltip content={<CustomTooltip />}></Tooltip>
          <Legend
            layout="horizontal" // Change layout to horizontal for bottom positioning
            align="center" // Align center for horizontal layout at the bottom
            verticalAlign="bottom" // Place the legend at the bottom of the chart
            wrapperStyle={{ paddingTop: "20px", fontSize: "9px" }} // Adjust padding and font size as needed
            iconType="circle" // Maintain the icon type as circle
            iconSize={8} // Maintain the icon size
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MarketChart;
