import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import supabase from "../../config/supabaseClient";
import "./lrtTable.scss";

export default function LrtTable() {
  const [blocks, setBlocks] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchBlock = async () => {
      const { data, error } = await supabase
        .from("tokens") // The table we are pulling from
        .select(); // Fetches all columns by default, ensure 'protocol_logo' is included

      if (error) {
        setFetchError("Could not fetch the token table");
        console.log(error);
      }

      if (data) {
        // Transforming the block data to match DataGrid rows structure
        const transformedData = data.map((item, index) => ({
          id: index + 1, // Adding a unique id for each row
          protocol_name: item.protocol_name,
          token_symbol: item.token_symbol,
          status: item.status,
          //total_supply: new Intl.NumberFormat().format(item.total_supply),
          total_supply: item.total_supply,
          // ETH PRICE FIXED
          tvl: item.total_supply * 3450, // Calculate TVL based on total supply and fixed price
          market_share: item.market_share.toFixed(4), // Formatting the market share
          total_points: item.total_points,
          protocol_logo: item.protocol_logo, // Ensure this field is included in your data
          link: item.link,
        }));
        // Sort the transformedData array by the total_supply column
        transformedData.sort((a, b) => b.market_share - a.market_share); // Change to 'b.total_supply - a.total_supply' if we want to sort by total supply
        setBlocks(transformedData);
      }
    };

    fetchBlock();
  }, []);

  const handleRowClick = (params) => {
    const url = params.row.link; // Get the link from the row data
    if (url) window.open(url, "_blank"); // Open in a new tab if the URL exists
  };

  // const getColorByRank = (rank) => {
  //   switch (rank) {
  //     case 1:
  //       return "gold";
  //     case 2:
  //       return "#ffffff";
  //     case 3:
  //       return "#CD7F32"; // Bronze color
  //     default:
  //       return "#B0B0B0"; // Default color for other rows
  //   }
  // };

  const columns = [
    // {
    //   field: "protocol_name",
    //   headerName: "Protocol Name",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const rank = blocks.findIndex((row) => row.id === params.row.id) + 1;
    //     const textColor = getColorByRank(rank);
    //     const placeholderIcon =
    //       rank <= 3 ? (
    //         <StarIcon style={{ color: textColor, fontSize: "1.2rem" }} />
    //       ) : null;
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           gap: 1,
    //           color: textColor,
    //         }}
    //       >
    //         {placeholderIcon}
    //         <img
    //           src={params.row.protocol_logo}
    //           alt=""
    //           style={{ width: 20, height: 20, borderRadius: 300 }}
    //         />
    //         {params.value}
    //       </Box>
    //     );
    //   },
    // },

    // Uncomment everything below to fix
    {
      field: "protocol_name",
      headerName: "Protocol Name",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <img
            src={params.row.protocol_logo}
            alt=""
            style={{ width: 20, height: 20, borderRadius: 300 }}
          />
          {params.value}
        </Box>
      ),
    },
    { field: "token_symbol", headerName: "Token Symbol", flex: 1 },
    {
      field: "total_supply",
      headerName: "Total Supply",
      flex: 1,
      valueFormatter: (params) => {
        return `${new Intl.NumberFormat().format(params.value.toFixed(0))} ETH`;
      },
    },
    {
      field: "tvl", // This should match the new tvl property in your rows data
      headerName: "TVL ($)",
      flex: 1,
      valueFormatter: (params) => {
        return `$${new Intl.NumberFormat().format(params.value.toFixed(0))}`;
      },
    },
    {
      field: "market_share",
      headerName: "Market Share",
      flex: 1,
      valueFormatter: (params) => {
        return (params.value * 100).toFixed(2) + "%";
      },
    },
    // Uncomment everything above to fix

    // {
    //   field: "total_points",
    //   headerName: "Total Points Supply",
    //   flex: 1,
    //   valueFormatter: (params) => {
    //     return new Intl.NumberFormat().format(params.value);
    //   },
    // },
  ];

  const dataGridStyles = {
    border: 0,
    fontFamily: "IBM Plex Mono, monospace",
    "& .MuiDataGrid-cell": {
      color: "#B0B0B0", // Adjust if necessary to change cell text color

      border: "none",
    },
    "& .MuiDataGrid-columnHeader": {
      color: "white", // Change column header text to white
      border: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: "#888890", // Ensures the text color of the header titles is white
    },
    "& .MuiDataGrid-columnHeaders": {
      color: "#888890", // Additional targeting for all header elements
      border: "none",
    },
    "& .MuiDataGrid-sortIcon": {
      color: "white", // Specifically targets the sort icons
    },
    "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
      color: "white", // Targets all SVG icons in the column headers, including sort icons
    },
    "& .MuiDataGrid-row": {
      backgroundColor: "#242424",
      marginBottom: "10px",
      borderRadius: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "translateY(-3px)",
        backgroundColor: "#242424",
        cursor: "pointer",
      },
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      border: "none",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
      visibility: "hidden", // Hides the column separators
    },

    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },

    "& .MuiDataGrid-columnHeader:focus-within": {
      outline: "none !important",
    },

    // "& .MuiDataGrid-withBorderColor": {
    //   display: 'none',
    // },

    // Continue with other styles as needed
  };

  return blocks ? (
    <Box
      sx={{
        height: "52.5vh",
        overflow: "auto",
        paddingRight: "25px",
        paddingLeft: "25px",
      }}
    >
      <DataGrid
        rows={blocks}
        columns={columns}
        sx={{
          ...dataGridStyles,
          minWidth: 1000,
        }}
        hideFooter={"true"}
        initialState={{
          pagination: {},
        }}
        // rowsPerPageOptions={[5]} // Corrected property name
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        onRowClick={handleRowClick} // Add the onRowClick prop here
      />
    </Box>
  ) : (
    ""
  );
}
