import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import supabase from "../../../../config/supabaseClient";
import tableStyles from "./TableStyles";

export default function AVSLiveTable() {
  const [combinedData, setCombinedData] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchBlock = async () => {
      try {
        const { data: liveData, error: liveError } = await supabase
          .from("live_avs")
          .select();
        if (liveError) throw liveError;

        const { data: upcomingData, error: upcomingError } = await supabase
          .from("upcoming_avs")
          .select();
        if (upcomingError) throw upcomingError;

        const mergedData = [
          ...liveData.map((item, index) => ({
            id: item.name,
            status: "Live",
            ...item,
          })),
          ...upcomingData.map((item, index) => ({
            id: item.name,
            status: "Upcoming",
            ...item,
          })),
        ];
        
        mergedData.sort((a, b) => b.tvl - a.tvl);

        setCombinedData(mergedData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setFetchError("Could not fetch the data");
      }
    };

    fetchBlock();
  }, []);

  const getRatioColor = (ratio) => {
    if (Number.isNaN(ratio)) {
      return "#B0B0B0";
    }
    if (ratio >= 1.5) {
      return "#19E590";
    } else if (ratio > 1) {
      return "#F4F19D";
    } else {
      return "#EE5555";
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "AVS Name",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth(
        "AVS Plus Additional Spacing long names"
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            minWidth: tableStyles.calculateMinWidth(params.value),
            overflow: "visible",
          }}
        >
          <img
            src={params.row.logo}
            alt=""
            style={{ width: 20, height: 20, borderRadius: 300 }}
          />
          {params.value}
        </Box>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Category"),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            color: tableStyles.getCategoryTextColor(params.value),
            backgroundColor: tableStyles.getCategoryBackgroundColor(
              params.value
            ),
            borderRadius: "0.5em",
            padding: "0.25em 0.5em",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Status"),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              color: params.value === "Live" ? "#19E590" : "#F4F19D",
              fontWeight: 500,
              marginRight: 1,
            }}
          >
            {params.value}
          </Box>
          {params.value === "Live" && (
            <Box
              sx={{
                width: 8,
                height: 8,
                backgroundColor: "#19E590",
                borderRadius: "50%",
              }}
            />
          )}
        </Box>
      ),
    },

    {
      field: "tvl",
      headerName: "Security TVL",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Security TVL"),
      valueFormatter: (params) => {
        if (params.value === null) {
          return "Coming soon";
        } else if (isNaN(params.value)) {
          return "-";
        } else {
          // ETH PRICE FIXED
          return `$${new Intl.NumberFormat().format(params.value * 3450)}`;
        }
      },
    },
    {
      field: "time_to_security",
      headerName: "Time to $1B Security",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Time to $100m Security"),
      // valueFormatter: (params) => {
      //   if (params.value === null) {
      //     return "Coming soon";
      //   } else if (isNaN(params.value)) {
      //     return "-"
      //   }else {
      //     return `${new Intl.NumberFormat().format(params.value)} days`;
      //   }
      // },

      renderCell: (params) => {
        const render_value =
          params.value === null
            ? "Coming Soon"
            : isNaN(params.value)
            ? "-"
            : `${new Intl.NumberFormat().format(params.value)} days`;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                color:
                  render_value === "-" || params.value === "Coming soon"
                    ? "#B0B0B0"
                    : "#22AAFF",
                fontWeight: 500,
              }}
            >
              {render_value}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "cost_of_security",
      headerName: "Cost of Security (Annum.)",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Cost of Security (Annum.)"),
      valueFormatter: (params) => {
        if (params.value === null) {
          return "Coming soon";
        } else if (isNaN(params.value)) {
          return "-";
        } else {
          // We know it is an integer and there is a value - but it might be free
          if (params.value === 0) {
            return "Free";
          } else {
            return `$${new Intl.NumberFormat().format(
              params.value
            )}.00 / dollar`;
          }
        }
      },
    },
    {
      field: "operatorCount",
      headerName: "Operators",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Operators Plus", true),
      valueFormatter: (params) => {
        if (params.value === null) {
          return "Coming soon";
        } else if (isNaN(params.value)) {
          return "-";
        } else {
          return `${new Intl.NumberFormat().format(params.value)}`;
        }
      },
    },
  ];

  return combinedData ? (
    <Box
      sx={{
        // height: "72.5vh",
        height: "855px",

        // TODO: NEED a fix here, this is not sustainable - w/o defined height here there is a small vertical scroll in the table
        // height: "90vh",
        // overflowX: "auto",
        // overflowY: "visible",
      }}
    >
      <DataGrid
        rows={combinedData}
        columns={columns}
        sx={{
          ...tableStyles.dataGridStyles,
          // height: "100%",
          // overflowY: "visible",
          // minWidth: 1000,
          // overflowX: "scroll",
        }}
        hideFooter={"true"}
        initialState={{
          pagination: {},
          pinnedColumns: { left: ["name"] },
        }}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
      />
    </Box>
  ) : (
    // {/* </Box> */}
    ""
  );
}
