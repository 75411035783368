const CustomLegend = ({ payload }) => {
  const entries = ["EigenLayer AVSs", "Other Networks"];
  const colorMap = {
    "EigenLayer AVSs": "#82ca9d",
    "Other Networks": "#FF4C52",
  };

  return (
    <div
      style={{
        display: "flex",
        // flexDirection: "column",
        gap: "2em",
        padding: "0em",
        fontSize: "0.5em",
        background: "transparent",
      }}
    >
      {entries.map((entry, index) => (
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <svg height="8" width="8" style={{ marginRight: "0.5em" }}>
            <circle cx="4" cy="4" r="4" fill={colorMap[entry]} />
          </svg>
          <span style={{ color: "#878787" }}>{entry}</span>
        </p>
      ))}
    </div>
  );
};

const BarChartCommon = {
  customLegend: <CustomLegend />,
};

export default BarChartCommon;
