import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Label,
  Cell,
  LabelList,
} from "recharts";
import supabase from "../../../../config/supabaseClient";
import BarChartCommon from "./BarChartCommon.jsx";
import "./ChartStyles.scss";
import eigenlayer_logo from "../../../../assets/eigenlayer_logo.jpg";

const BootstrapBarChart = ({ title }) => {
  const [data, setData] = useState([]);
  const [logoMap, setLogoMap] = useState({});
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data, error } = await supabase
          .from("avs_candidates")
          .select("name, token_volatility, eth_volatility, logo");

        if (error) {
          console.error("Error fetching data: ", error);
          setFetchError(error.message);
          return;
        }

        // Manually add an item to the data array
        const newItem = {
          name: "Avg. AVS",
          token_volatility: 3.43,
          eth_volatility: 0,
          logo: eigenlayer_logo,
        };

        data.push(newItem);
        data.sort((a, b) => a.token_volatility - b.token_volatility);

        setData(data);

        // Create a map of names to logos
        const logoMapping = data.reduce((map, item) => {
          map[item.name] = item.logo;
          return map;
        }, {});

        setLogoMap(logoMapping);
      } catch (error) {
        console.error("Error fetching data: ", error.message);
        setFetchError(error.message);
      }
    };

    fetchData();
  }, []);

  const CustomBar = (props) => {
    const { fill, x, y, width, height, index } = props;

    // Check if the current bar is the first one
    const isFirstBar = index === 0;

    // Determine fill color based on whether it's the first bar or not
    const barFill = isFirstBar ? "#82ca9d" : "#fff";

    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={`url(#gradient${index})`}
        rx={8}
        ry={8}
      />
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "black",
            color: "#888",
            padding: "5px",
            borderRadius: "5px",
            fontSize: "12px",
          }}
        >
          <p
            style={{ color: data.name === "Avg. AVS" ? "#82ca9d" : "#EA4646" }}
          >
            {`${label}`}
          </p>
          <p style={{ color: "#b0b0b0" }}>
            30 day std. dev. : {data.token_volatility} %
          </p>
          {/* <p className="intro" style={{ color: "#b0b0b0" }}>
            ETH Volatility - 30 day std. dev : {data.eth_volatility} %
          </p> */}
        </div>
      );
    }

    return null;
  };

  const renderCustomLabel = (props) => {
    const { x, y, width, height, name, value } = props;
    const logo = logoMap[name];

    // Compute offsets and adjustments
    const xOffset = width / 2 - 50; // Adjust X to center the foreignObject
    // const yOffset = (name === "Avg. AVS") ? 8 : 28; // Adjust Y if value is 0
    const yOffset = 28;
    const adjustedY = y - yOffset - 10; // Move up by height of the bar + additional offset

    return (
      <g transform={`translate(${x + xOffset}, ${adjustedY})`}>
        <foreignObject width={100} height={100} style={{ overflow: "visible" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            {logo && (
              <img
                src={logo}
                alt={name}
                style={{ width: "1em", height: "1em", borderRadius: "50%" }}
              />
            )}
            <span
              style={{
                fontSize: "0.625em",
                color: "white",
                marginTop: logo ? "0.5em" : "0",
              }}
            >
              {value} %
            </span>
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <div className="bar-chart-container">
      <p className="title">{title}</p>
      <ResponsiveContainer>
        <BarChart data={data}>
          <defs>
            {data.map((entry, index) => (
              <>
                <linearGradient
                  id={`gradient${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="0%"
                    stopColor={index === 0 ? "#82ca9d" : "#EA4646"}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor={index === 0 ? "#82ca9d" : "#EA4646"}
                    stopOpacity={index === 0 ? 0.5 : 0.25}
                  />
                </linearGradient>
                <linearGradient
                  id={`gradient-hover-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor={index === 0 ? "#82EA9d" : "#FF6868"} stopOpacity={1} />
                  <stop offset="100%" stopColor={index === 0 ? "#82EA9d" : "#FF6868"} stopOpacity={0.25} />
                </linearGradient>
              </>
            ))}
          </defs>
          <XAxis
            dataKey="name"
            tick={{
              dy: 10,
              fill: "#FFFFFF", // Sets the text color
            }}
            // angle={-15} // Angle the labels
            style={{ fontSize: "0.625em" }}
          />
          <YAxis tick={false}>
            <Label
              value="Volatility"
              angle={-90}
              style={{ fontSize: "0.625em" }}
              fill="#FFF"
            />
          </YAxis>
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Bar dataKey="token_volatility" fill="#FFF3B3" shape={<CustomBar />}>
            {data.map((entry, index) => (
              // fill={`url(#gradient${index})`}
              <Cell
                key={index}
                fill={`url(#gradient-hover-${index})`}
                radius={8}
              />
            ))}
            <LabelList content={renderCustomLabel} />
          </Bar>
          <Legend
            content={BarChartCommon.customLegend}
            // layout="horizontal"
            align="center"
            verticalAlign="top"
            wrapperStyle={{
              // right: 0,
              left: "1em",
              transform: "translate(0, -1.5em)",
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      {fetchError && <p style={{ color: "red" }}>{fetchError}</p>}
    </div>
  );
};

export default BootstrapBarChart;
