const dataGridStyles = {
  border: 0,
  fontFamily: "IBM Plex Mono, monospace",
  "& .MuiDataGrid-cell": {
    color: "#B0B0B0", // Adjust if necessary to change cell text color
    border: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    color: "white", // Change column header text to white
    border: "none",
    // overflow: "visible",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: "#B0B0B0", // Ensures the text color of the header titles is white
    // overflow: "visible",
  },
  "& .MuiDataGrid-columnHeaders": {
    color: "#B0B0B0", // Additional targeting for all header elements
    backgroundColor: "#242424",
    borderBottom: "none",
  },

  "& .MuiDataGrid-sortIcon": {
    color: "white", // Specifically targets the sort icons
  },
  "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
    color: "white", // Targets all SVG icons in the column headers, including sort icons
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "#161616",
    height: "100px",
    borderTop: "1px solid #1F1F1F",
    "&:hover": {
      // backgroundColor: "#242424",
      // cursor: "pointer",
    },
  },

  "& .MuiDataGrid-columnSeparator": {
    display: "none",
    visibility: "hidden", // Hides the column separators
  },

  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },

  "& .MuiDataGrid-columnHeader:focus-within": {
    outline: "none !important",
  },

  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
    background: "transparent", // Makes the track transparent
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
    background: "#2E2E2E", // Thumb color when not hovered
    borderRadius: "5px",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
    background: "white", // Thumb color when hovered
  },

  // For Firefox (Note: Firefox does not support transparent tracks as of my last update)
  "& .MuiDataGrid-virtualScroller": {
    scrollbarWidth: "thin",
    scrollbarColor: "#2E2E2E transparent", // Thumb color and track color
  },
};

// To modify the color of the category text
const getCategoryTextColor = (category) => {
  switch (category) {
    case "Policy":
      return "#00E100";
    case "Co-processor":
      return "#FB68F5";
    case "Oracle":
      return "#00E009";
    case "Interoperability":
      return "#B3EDFF";
    case "Data Availability":
      return "#FF9900"; // #FF0000
    default:
      return "#FFF3B3";
  }
};

// To modify the background color of the category text
const getCategoryBackgroundColor = (category) => {
  switch (category) {
    case "Policy":
      return "#1C2721";
    case "Co-processor":
      return "#251C27";
    case "Oracle":
      return "#0068044D";
    case "Interoperability":
      return "#0072954D";
    case "Data Availability":
      return "#6030004D"; // #7000004D
    default:
      return "#927B004D";
  }
};

// Assuming the base font size is 16 pixels
const baseFontSize = 16; // Base font size in pixels
const minimum_width_em = 15

const calculateMinWidth = (headerName, override_minimum = false) => {
  const baseWidthEm = 1; // Base width in ems
  const widthPerCharEm = 0.6; // Width per character in ems
  let totalEm = 0;

  if (! override_minimum) {
    totalEm = Math.max(minimum_width_em, baseWidthEm + (headerName.length * widthPerCharEm));
  } else {
    totalEm = baseWidthEm + (headerName.length * widthPerCharEm)
  }

  return totalEm * baseFontSize; // Convert em to pixels
};

const tableStyles = {
  dataGridStyles: dataGridStyles,
  getCategoryBackgroundColor: getCategoryBackgroundColor,
  getCategoryTextColor: getCategoryTextColor,
  calculateMinWidth: calculateMinWidth,
};

export default tableStyles;
