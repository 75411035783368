import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function AVSOverviewTabs({ onTabChange, tabValues }) {
  const [selectedTab, setSelectedTab] = useState("Active AVSs");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    onTabChange(tab); // Call the onTabChange function passed as a prop
  };

  return (
    <Tabs
      value={selectedTab}
      sx={{
        "& .MuiTabs-indicator": {
          backgroundColor: "#fdefd3", // change indicator color
        },
        "& .MuiTab-root": {
          color: "#6e6c6c", // change tab text color
          textTransform: "none", // disable uppercase text
          marginRight: "1em",
          marginLeft: "0.125em"
        },
        "&:hover": {
          color: "#ffffff", // Tab text color on hover
        },
      }}
    >
      {tabValues.map((tabValue) => (
        <Tab
          key={tabValue}
          label={tabValue}
          value={tabValue}
          onClick={() => handleTabClick(tabValue)}
          sx={{
            textDecoration: "none",
            fontFamily: "IBM Plex Mono, monospace",
            color: selectedTab === tabValue ? "#fdefd3" : "#6e6c6c", // conditional color styling
            textTransform: "none", // ensure text is not uppercase

            "&.Mui-selected": {
              color: "#fdefd3", // change selected tab text color
            },

            "&:not(.Mui-selected):hover": {
              color: "#ffffff", // Tab text color on hover when not selected
            },
            
          }}
        />
      ))}
    </Tabs>
  );
}

export default AVSOverviewTabs;
