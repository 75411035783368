import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import { ThemeProvider, createTheme} from '@mui/material/styles';
import App from "./App";
import "./index.scss"

const THEME = createTheme({
  typography: {
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={THEME}>

      <App />
      </ThemeProvider>

    </React.StrictMode>
);
