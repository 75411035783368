import React, { useState, useEffect } from "react";
import LrtPage from "./LrtPage/LrtPage";
import AVSOverview from "./AVS/Overview/AVSOverview";
import AboutPage from "./About/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import MobileMenu from "./components/MobileMenu"; // Import your MobileMenu component
import AVSIntel from "./AVS/Intel/AVSIntel";
import AVSRiskAnalysis from "./AVS/RiskAnalysis/AVSRiskAnalysis";

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <div className="app-container">
        {/* {isMobile ? <MobileMenu />: <Navbar />} */}
        {isMobile ? <MobileMenu /> : <Navbar />}

        <div className="main-content">
          <Routes>
            <Route path="/" element={<AVSOverview />} />
            <Route path="/lrt" element={<LrtPage />} />
            {/* <Route path="/about" element={<AboutPage />} /> */}
            
            {/* <Route path="/avs/overview" element={<AVSOverview />} /> */}
            {/* TODO: UNCOMMENT WHEN MAKING AVS PUBLIC */}
            {/* <Route path="/avs/risk-analysis" element={<AVSRiskAnalysis />} />
            <Route path="/avs/intel" element={<AVSIntel />} /> */}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
