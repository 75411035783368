import React, { useState, useEffect } from "react";
import AVSOverviewTable from "./OverviewComponents/OverviewTables/AVSOverviewLiveTable";
import AVSOverviewHeaderGrid from "./OverviewComponents/AVSOverviewHeaderGrid";
import AVSOverviewTabs from "./OverviewComponents/AVSOverviewTabs";
import AVSUpcomingTable from "./OverviewComponents/OverviewTables/AVSOverviewUpcomingTable";
import AVSCandidateTable from "./OverviewComponents/OverviewTables/AVSOverviewCandidatesTable";

// Charts for on top
import AVSOverviewLineChart from "./OverviewComponents/AVSOverviewLineChart";
import CandidateBarChart from "./OverviewComponents/OverviewBarCharts/CandidateBarChart";
import UpcomingBarChart from "./OverviewComponents/OverviewBarCharts/UpcomingBarChart";

// Testing overall bar chart
import AVSOverviewBarChart from "./OverviewComponents/AVSOverviewBarChart";

import Grid from "@mui/material/Grid";
import "./AVSOverview.scss";
import BootstrapBarChart from "./OverviewComponents/OverviewBarCharts/BootstrapBarChart";

const AVSOverview = () => {
  const [selectedTab, setSelectedTab] = useState("Active AVSs");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const table_options = ["Active AVSs", "AVS Candidates"];

  const live_avs_header_info = {
    title1: "Live AVSs",
    info1: "13",
    title2: "Avg. Cost of Security (Annum.)",
    info2: "Free",
    title3: "Price Volatility of Security",
    info3: "3.43% Std. Dev.",
  };

  const upcoming_avs_header_info = {
    title1: "Live AVSs",
    info1: "10",
    title2: "Avg. Cost of $100m Security",
    info2: "$41,667 / month",
    title3: "Avg. Time to $100m Security",
    info3: "14.16 Days",
  };

  // Allows us to dynamically render the table based on the selected tab
  const renderAVSTable = () => {
    switch (selectedTab) {
      case "Active AVSs":
        return <AVSOverviewTable />;
      // case "Upcoming AVSs":
      //   return <AVSUpcomingTable />;
      case "AVS Candidates":
        return <AVSCandidateTable />;
      default:
        return <AVSOverviewTable />;
    }
  };

  // Rendering the left cell of the top grid
  const renderLeftCell = () => {
    switch (selectedTab) {
      case "Active AVSs":
        return <AVSOverviewHeaderGrid data={live_avs_header_info} />;
      // case "Upcoming AVSs":
      //   return <AVSOverviewHeaderGrid data={upcoming_avs_header_info} />;
      case "AVS Candidates":
        return (
          <div style={{ position: "relative", height: "100%" }}>
            <div style={{ visibility: "hidden", width: "40%" }}>
              <AVSOverviewHeaderGrid data={live_avs_header_info} />
            </div>

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <CandidateBarChart title="Cost of Economic Security" />
            </div>
          </div>
        );
      default:
        return <AVSOverviewHeaderGrid data={live_avs_header_info} />;
    }
  };

  // Rendering the right cell of the top grid
  const renderRightCell = () => {
    switch (selectedTab) {
      case "Active AVSs":
        return (
          <div style={{ position: "relative", height: "100%" }}>
            <div style={{ visibility: "hidden", width: "40%" }}>
              <AVSOverviewHeaderGrid data={live_avs_header_info} />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <AVSOverviewLineChart title="Total Restaked TVL" />;
            </div>
          </div>
        );
      // case "Upcoming AVSs":
      //   return (
      //     <div style={{ position: "relative", height: "100%" }}>
      //       <div style={{ visibility: "hidden", width: "40%" }}>
      //         <AVSOverviewHeaderGrid data={live_avs_header_info} />
      //       </div>
      //       <div
      //         style={{
      //           position: "absolute",
      //           top: 0,
      //           left: 0,
      //           right: 0,
      //           bottom: 0,
      //         }}
      //       >
      //         <UpcomingBarChart title="Value Committed" />;
      //       </div>
      //     </div>
      //   );
      case "AVS Candidates":
        return (
          <div style={{ position: "relative", height: "100%" }}>
            <div style={{ visibility: "hidden", width: "40%" }}>
              <AVSOverviewHeaderGrid data={live_avs_header_info} />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <BootstrapBarChart title="Price Volatility of Economic Security Collateral" />
            </div>
          </div>
        );
      default:
        return <AVSOverviewLineChart title="Total Restaked TVL" />;
    }
  };

  return (
    <div className="overview-container">
      <div className="tab-section">
        <AVSOverviewTabs
          onTabChange={handleTabChange}
          tabValues={table_options}
        />
      </div>
      <Grid container className="top-data" spacing={2}>
        <Grid item xs={12} lg={selectedTab == "AVS Candidates" ? 6 : 2.5}>
          {renderLeftCell()}
        </Grid>
        <Grid item xs={12} lg={selectedTab == "AVS Candidates" ? 6 : 9.5}>
          {renderRightCell()}
        </Grid>
      </Grid>
      {renderAVSTable()}
    </div>
  );
};

export default AVSOverview;
