import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import supabase from "../../../config/supabaseClient";
import "./AVSOverviewLineChart.scss";

const AVSOverviewLineChart = ({ title }) => {
  const [chartData, setChartData] = useState([]);
  const [uniqueProtocols, setUniqueProtocols] = useState([]);
  const [colorMap, setColorMap] = useState({});

  const predefinedColors = [
    "#FFE811", "#40E0D0", "#B0C4DE", "#00EE00", "#C99410",
    "#6495ED", "#911EB4", "#FF3123", "#2BA33A", "#F0E68C"
  ];  // Ten colors for the top ten protocols

  useEffect(() => {
    const fetchData = async () => {
      let { data, error } = await supabase
        .from("avs_chart_duplicate")
        .select("timestamp, tvl, name")
        .gte("timestamp", 1713003394)
        .order("timestamp", { ascending: true });

      if (error) {
        console.error("Error fetching data:", error);
        return;
      }

      // Find the final TVL for each protocol based on the last entry
      const finalTvl = {};
      data.forEach(({ name, tvl, timestamp }) => {
        const shortenedName = name.split(' ').slice(0, 2).join(' ');
        // Check if a newer timestamp has been recorded or if the protocol has not been added yet
        if (!finalTvl[shortenedName] || finalTvl[shortenedName].timestamp < timestamp) {
          finalTvl[shortenedName] = { tvl, timestamp };
        }
      });

      // Sort protocols by final TVL and take the top 10
      const sortedProtocols = Object.entries(finalTvl)
        .sort((a, b) => b[1].tvl - a[1].tvl)
        .slice(0, 8)
        .map(entry => entry[0]);

      setUniqueProtocols(sortedProtocols);

      // Assign colors based on the sorted list
      const newColorMap = sortedProtocols.reduce((acc, protocol, index) => {
        acc[protocol] = predefinedColors[index % predefinedColors.length];
        return acc;
      }, {});

      setColorMap(newColorMap);

      // Process chart data only for the top 10 protocols
      const processedData = processData(data, new Set(sortedProtocols));
      setChartData(processedData);
    };

    fetchData();
  }, []);

  const processData = (data, validProtocols) => {
    // Initial data processing setup
    const allTimeStamps = new Set(data.map(item => item.timestamp));
    const processedData = {};

    allTimeStamps.forEach(timestamp => {
      processedData[timestamp] = {};
      validProtocols.forEach(protocol => {
        processedData[timestamp][protocol] = 0;
      });
    });

    data.forEach(({ timestamp, tvl, name }) => {
      const shortenedName = name.split(' ').slice(0, 2).join(' ');
      if (validProtocols.has(shortenedName)) {
        processedData[timestamp][shortenedName] = tvl;
      }
    });

    return Object.keys(processedData).map(timestamp => ({
      timestamp: new Date(timestamp * 1000).toISOString().split('T')[0], 
      ...processedData[timestamp]
    }));
  };

  // Take the namemap, colors, and colormap from the common source
  // const chartColors = [
  //   "#FF8818", // Orange
  //   "#FFE811", // Gold
  //   "#00DD00", // Green
  //   "#FF3123",
  //   "#6495ED", // Cornflower Blue
  //   "#BA55D3",
  //   "#4444FF",
  //   // "#6B8E23", // Olive Drab
  //   "#C99410", // Goldenrod
  //   "#40E0D0", // Turquoise
  //   "#B0C4DE", // Light Steel Blue
  //   // "#BA55D3", // Medium Orchid
  //   "#F0E68C", // Khaki
  // ];

  // const colorMap = {
  //   "AltLayer" : "#FFE811", // Green
  //   "Automata" : "#40E0D0", // Turquoise
  //   "Brevis" : "#B0C4DE", // Orange
  //   "EigenDA" : "#00EE00", // Gold "#00DD00
  //   "eoracle" : "#C99410",
  //   "Lagrange" : "#6495ED", // Cornflower Blue
  //   "Omni" : "#911EB4", // Goldenrod
  //   "Witness" : "#2BA33A",
  //   "Xterio" : "#FF3123",
  //   "DODOchain" : "#F0E68C",
  //   "OpenLayer" : "#DDDDDD",
  //   // "#6B8E23", // Olive Drab
  //   // "#B0C4DE", // Light Steel Blue
  //   // // "#BA55D3", // Medium Orchid
  //   // "#F0E68C", // Khaki
  //   // "AltLayer Mach": "#E6194B", // Red
  //   // "Automata": "#F58231", // Orange
  //   // "Brevis": "#FFE119", // Yellow
  //   // "EigenDA": "#BCF60C", // Light green
  //   // "eoracle": "#3CB44B", // Green
  //   // "Lagrange": "#42D4F4", // Light blue
  //   // "Omni": "#4363D8", // Blue
  //   // "Witness Chain": "#911EB4", // Purple
  //   // "Xterio Mach": "#FABED4", // Light pink
  // }

  // Render areas for each token with gradient fill
  const renderAreas = uniqueProtocols
    ? uniqueProtocols.map((protocol, index) => {
        return (
          <Area
            key={protocol}
            type="monotone"
            dataKey={protocol}
            name={protocol}
            stroke={colorMap[protocol]}
            fill={`url(#color${index})`} // Gradient fill
            dot={false}
          />
        );
      })
    : null;

  // Customized so that we can ignore 0-entries
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const sortedPayload = [...payload].sort((a, b) => {
        return b.value - a.value;
      });

      return (
        <div
          className="custom-tooltip"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.75em",
            padding: "1em 5px",
            fontSize: "0.625em",
            backgroundColor: "rgba(0, 0, 0)",
            color: "white",
          }}
        >
          {sortedPayload.map((entry, index) => {
            return (
              <p
                style={{
                  color: colorMap[entry.name],
                }}
                key={`item-${index}`}
              >
                {`${entry.name} : ${new Intl.NumberFormat().format(
                  Math.round(entry.value)
                )} ETH`}
              </p>
            );
          })}
        </div>
      );
    }
  };

  // Customized to re-order entries to be in line with the order on the bar chart
  const CustomLegend = ({ payload }) => {
    // Reverse the payload array for displaying in reverse order
    // This is the main reason we are doing the custom legend!
    const reversedPayload = [...payload].reverse();

    return (
      <div
        style={{
          display: "flex",
          // flexDirection: "column",
          gap: "1em",
          // padding: "0em 0em 0em 0em",
          fontSize: "0.5em",
          background: "transparent",
        }}
      >
        {reversedPayload.map((entry, index) => (
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg height="8" width="8" style={{ marginRight: "0.5em", overflow: "visible" }}>
              <circle cx="4" cy="4" r="4" fill={colorMap[entry.value]} />
            </svg>
            <span style={{ color: "#878787" }}>{entry.value}</span>
          </p>
        ))}
      </div>
    );
  };

  return (
    <div className="line-chart-container">
      <p className="title">{title}</p>
      <ResponsiveContainer>
        <AreaChart data={chartData}>
          <defs>
            {uniqueProtocols.map((protocol, index) => {
              return <linearGradient
                key={protocol}
                id={`color${index}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={colorMap[protocol]}
                  stopOpacity={0.075}
                />
                <stop
                  offset="100%"
                  stopColor={colorMap[protocol]}
                  stopOpacity={0.01}
                />
              </linearGradient>
          })}
          </defs>
          <CartesianGrid stroke="#2e2e2e" vertical={false} />
          <XAxis dataKey="someKey" />
          <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
          <Tooltip content={<CustomTooltip />}></Tooltip>
          <Legend
            content={<CustomLegend />}
            // layout="vertical"
            align="center"
            verticalAlign="top"
            wrapperStyle={{
              // top: "50%",
              left: "1em",
              transform: "translate(0, -1em)",
            }}
          />
          {renderAreas}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AVSOverviewLineChart;
