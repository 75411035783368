import React, { useState, useEffect } from 'react';
import {FormControl, Select, MenuItem, Box, Tabs, Tab} from '@mui/material';
import "./AVSRiskAnalysis.scss"
import supabase from "../../config/supabaseClient";
import LrtTable from '../../LrtPage/LrtTable/LrtTable';


const AVSRiskAnalysis = () => {
  const [selectedProtocol, setSelectedProtocol] = useState("Ether Finance");
  const [selectedTable, setSelectedTable] = useState("Flows");

  // For selecting the protocol at the top of the page
  const handleProtocolChange = (event) => {
    console.log("old selected", selectedProtocol, "new target val", event.target.value);
    setSelectedProtocol(event.target.value);
  }


  /**
   * Everything table related
   */
  const [blocks, setBlocks] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchBlock = async () => {
      const { data, error } = await supabase
        .from("tokens") // The table we are pulling from
        //TODO: Temporary pulling minimum
        .select("protocol_name, protocol_logo, token_symbol"); // Fetches all columns by default, ensure 'protocol_logo' is included

      if (error) {
        setFetchError("Could not fetch the token table");
        console.log(error);
      }

      if (data) {
        // Transforming the block data to match DataGrid rows structure
        const transformedData = data.map((item, index) => ({
          id: index + 1, // Adding a unique id for each row
          protocol_name: item.protocol_name,
          protocol_logo: item.protocol_logo, 
        }));

        // Sort the transformedData array alphabetically
        transformedData.sort((a, b) => a.protocol_name.localeCompare(b.protocol_name)); 
        setBlocks(transformedData);
        console.log(transformedData);
      }
    };

    fetchBlock();
  }, []);

  // Use the selectedProtocol in order to setup the correct table
  const renderTable = () => {
    // Just a sketch of what the logic could be
    // switch (selectedTable) {
    //   case "Flows":
    //     return <FlowsTable props={{selectedProtocol}}/>
    //   case "Token Activity":
    //     return <TokenActivityTable props={{selectedProtocol}}/>
    //   case "Points Breakdown":
    //     return <PointsTable props={{selectedProtocol}}/>
    // }


    // Temp
    return <div>{selectedProtocol}</div>
  }

  // For changing the tab to a different table
  const handleTableChange = (event) => {
    setSelectedTable(event.target.value);
  }

  return (
    <div className='avs-risk-analysis-container'>
      <FormControl>
        <Select
          id="protocol-select"
          value={selectedProtocol}
          onChange={handleProtocolChange}
          renderValue={(selected) => {
            // Find the block with the matching protocol_name
            const selectedBlock = blocks.find(block => block.protocol_name === selected);
            if (!selectedBlock) return selected;
        
            return (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, color: "white" }}>
                <img
                  src={selectedBlock.protocol_logo}
                  alt=""
                  style={{
                    width: "2em",
                    height: "2em",
                    borderRadius: '50%',
                  }}
                />
                {selectedBlock.protocol_name}
              </Box>
            );
          }}
          sx={{
            width: "20em",
            height: "3em",
            borderRadius: "10px",
            fontFamily: "IBM Plex Mono, monospace",
            fontSize: "14px",
            color: "black",
            border: "1px solid #B0B0B0",
            // borderRadius: "5px",
            // backgroundColor: "green", // Text color
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#2E2E2E", // Border color
              borderWidth: "0.00px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#B0B0B0", // Hover state border color
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              // Makes it so that there is no blue focused outline
              borderWidth: "0px", 
            },
            "& .MuiSvgIcon-root": {
              color: "white", // Dropdown icon color
            },
          }}
          MenuProps={{
            // Dropdown menu style properties
            PaperProps: {
              sx: {
                backgroundColor: "#2E2E2E",
                boxShadow: "none",
                borderRadius: "10px",
                fontFamily: "IBM Plex Mono, monospace",
              },
            },
          }}
        >
    
          {blocks.map((block, index) => (
            <MenuItem
              value={block.protocol_name}
              key={index}
              sx={{
                display: "flex",
                gap: "0.5em",
                color: "white",
              }}
            >
              <img src={block.protocol_logo} 
                alt='' 
                style={{
                  width: "2em",
                  height: "2em",
                  borderRadius: "50%",
                }}>
              </img>
              {block.protocol_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* <Tabs
        value={selectedTable}
        onChange={handleTableChange}
        aria-label="Table tabs"
      >
        <Tab label/>

      </Tabs> */}
      
      <LrtTable />

    </div>


  )
}

export default AVSRiskAnalysis;