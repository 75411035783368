const chartColors = [
  "#6495ED", // Cornflower Blue
  "#FFE811", // Gold
  "#FF69B4", // Hot Pink
  "#DD6347", // Tomato
  "#6B8E23", // Olive Drab
  "#C99410", // Goldenrod
  "#20B2AA", // Light Sea Green
  "#40E0D0", // Turquoise
  "#9370DB", // Medium Purple
  "#3CB371", // Medium Sea Green
  "#FFA07A", // Light Salmon
  "#B0C4DE", // Light Steel Blue
  "#BA55D3", // Medium Orchid
  "#98FB98", // Pale Green
  "#F0E68C", // Khaki
];

const tokenToName = {
  csETH: "Claystack",
  eETH: "Ether Finance",
  genETH: "Genesis",
  primeETH: "Prime Staked",
  pufETH: "Puffer Finance",
  rsETH: "Kelp DAO",
  rswETH: "Swell",
  uniETH: "Bedrock",
  ezETH: "Renzo",
  "m*ETH": "Eigenpie",
  "in*ETH": "Inception",
}

const LRTChartCommon = {
  // A consistent array of colors to use for all of the charts
  colors: chartColors,
  nameMap: tokenToName,

  // Map the names to their colors (alphabetical order)
  colorMap: {}
}

// But define the colorMap out here
LRTChartCommon.colorMap = Object.keys(LRTChartCommon.nameMap)
  .sort((a, b) => LRTChartCommon.nameMap[a].localeCompare(LRTChartCommon.nameMap[b]))
  .reduce((acc, key, index) => {
    // Use the friendly name from nameMap as the key in colorMap
    const name = LRTChartCommon.nameMap[key];
    acc[name] = LRTChartCommon.colors[index % LRTChartCommon.colors.length]; // Use modulo for cycling colors
    return acc;
  }, {});

export default LRTChartCommon;