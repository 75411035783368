import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import supabase from "../../config/supabaseClient";
import LRTChartCommon from "./lrtChartCommon";

const Chart = ({ title }) => {
  const [blocks, setBlocks] = useState([]);
  const [uniqueTokens, setUniqueTokens] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let { data, error } = await supabase
        .from("bar_chart")
        .select("block_number, token_symbol, total_supply")
        .gte("block_number", 18850000)
        .order('block_number', { ascending: true });
  
      if (error) {
        console.error("Error fetching data: ", error);
        setFetchError(error.message);
        return;
      }
  
      // Process the data
      const processedData = processData(data);
      setBlocks(processedData);

      // Calculate unique tokens after data fetch
      const tokens = new Set(data.map(item => item.token_symbol));
      setUniqueTokens([...tokens]);
    };
  
    fetchData();
  }, []);
  
  const processData = (data) => {
    // First, create a Set of all unique block numbers and tokens
    const allBlockNumbers = new Set(data.map(item => item.block_number));
    const allTokens = new Set(data.map(item => item.token_symbol));
  
    // Initialize an object to hold processed data for each block
    const processedData = {};
  
    // Populate processedData with an entry for each block that includes all tokens
    allBlockNumbers.forEach(block => {
      processedData[block] = {};
      allTokens.forEach(token => {
        processedData[block][token] = 0; // Default total_supply
      });
    });
  
    // Fill in the actual total_supply values from the data
    data.forEach(({ block_number, token_symbol, total_supply }) => {
      processedData[block_number][token_symbol] = total_supply;
    });
  
    // Convert processedData into an array suitable for Recharts
    const chartData = Object.keys(processedData).map(block_number => {
      return { block_number, ...processedData[block_number] };
    });
  
    return chartData;
  };

  // Take the namemap, colors, and colormap from the common source
  const nameMap = LRTChartCommon.nameMap;
  const barColors = LRTChartCommon.colors;
  const colorMap = LRTChartCommon.colorMap;

  // Update the formatter function to include the specific naming conditions
  const formatLegendText = (value) => {
    return nameMap[value] || value;
  };

  // Render bars when we have loaded the data
  const renderBars = uniqueTokens ? uniqueTokens.sort((a, b) => {
    const nameA = nameMap[a], nameB = nameMap[b];

    if (! nameB) {
      return nameA;
    } else if (! nameA) {
      return nameB;
    }

    return nameA.localeCompare(nameB);
  }).map((token, index) => (
    <Bar
      key={token}
      dataKey={token}
      stackId="a"
      fill={barColors[index % barColors.length]}
      name={formatLegendText(token)}
    />
  )) : null;

  // Customized so that we can ignore 0-entries
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      
      // Filter out entries with zero values
      const validPayload = payload.filter(entry => entry.value > 0);
      if (validPayload.length === 0) return null;

      // Reverse to match with the bars
      const reversedValidPayload = [...validPayload].reverse();
      console.log("val", validPayload)
      console.log("rev", reversedValidPayload)
  
      return (
        <div className="custom-tooltip" style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.75em",
          padding: "1em 5px",
          fontSize: "10px",
          backgroundColor: "rgba(0, 0, 0)",
          color: "white",

        }}>
          {reversedValidPayload.map((entry, index) => {
            return <p 
              style={{
                color: colorMap[entry.name],
              }}
              key={`item-${index}`}>
              {`${nameMap[entry.name] || entry.name} : ${new Intl.NumberFormat().format(Math.round(entry.value))} ETH`}
            </p>
          })}
        </div>
      );
    }
  };

  // Return NULL when we haven't loaded the data yet
  return ( blocks ?
    <div style={{borderRadius: "0.5em"}}>
      <p
        style={{
          textAlign: "left",
          color: "white",
          marginBottom: "2vh",
          fontSize: "14px",
        }}
      >
        {title}
      </p>
      <ResponsiveContainer height={200}>
        <BarChart data={blocks}>
          <CartesianGrid stroke="#2e2e2e" vertical={false} />
          <XAxis dataKey="somekey" />
          <Tooltip content={<CustomTooltip />} />
          <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
          <Legend
            iconType="circle"
            iconSize="8"
            layout="horizontal"
            align="center"
            verticalAlign="bottom"
            wrapperStyle={{ fontSize: "9px", marginBottom: "0px" }}
            formatter={(value) => formatLegendText(value)}
          />
          {renderBars}
        </BarChart>
      </ResponsiveContainer>
      {fetchError && <p style={{ color: 'red' }}>{fetchError}</p>}
    </div> : null
  );
};

export default Chart;
