import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
} from "recharts";
import supabase from "../../../../config/supabaseClient";
import BarChartCommon from "./BarChartCommon.jsx";
import "./ChartStyles.scss";
import { color } from "chart.js/helpers";
import eigenlayer_logo from "../../../../assets/eigenlayer_logo.jpg";

const CandidateBarChart = ({ title }) => {
  const [data, setData] = useState([]);
  const [logoMap, setLogoMap] = useState({});
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data, error } = await supabase
          .from("avs_candidates")
          .select("name, security_cost, security_cost_eigenlayer, logo");

        if (error) {
          console.error("Error fetching data: ", error);
          setFetchError(error.message);
          return;
        }

        // Manually add an item to the data array
        const newItem = {
          name: "Avg. AVS",
          security_cost: 0,
          security_cost_eigenlayer: 0,
          logo: eigenlayer_logo,
        };

        data.push(newItem);
        data.sort((a, b) => a.security_cost - b.security_cost);
        setData(data);

        // Create a map of names to logos
        const logoMapping = data.reduce((map, item) => {
          map[item.name] = item.logo;
          return map;
        }, {});

        setLogoMap(logoMapping);
      } catch (error) {
        console.error("Error fetching data: ", error.message);
        setFetchError(error.message);
      }
    };

    fetchData();
  }, []);

  const CustomBar = (props) => {
    // const [isHovered, setIsHovered] = useState(false);
    const { fill, x, y, width, height, index } = props;

    // To determine bar colors
    const isFirstBar = index === 0;
    const barFill = isFirstBar ? "#82ca9d" : "#FF4C52";
    // const barFill = isFirstBar && !isHovered ? "#82ca9d" : (isHovered ? "#FFF3B3" : "#FF4C52");

    const adHeight = height + 8;

    return (
      <rect
        x={x}
        y={y - (isFirstBar ? adHeight : 0)} // Adjust the y position to align with the axis
        width={width}
        height={isFirstBar ? adHeight : height} // Set a small height for the bar
        fill={`url(#gradient${index})`}
        rx={8}
        ry={8}
      />
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "black",
            color: "#888",
            padding: "5px",
            borderRadius: "5px",
            fontSize: "12px",
          }}
        >
          <p
            style={{ color: data.name === "Avg. AVS" ? "#82ca9d" : "#EA4646" }}
          >
            {`${label}`}
          </p>
          <p style={{ color: "#b0b0b0" }}>
            Security Cost : {data.security_cost}
          </p>
        </div>
      );
    }

    return null;
  };

  const renderCustomLabel = (props) => {
    const { x, y, width, height, name, value } = props;
    const logo = logoMap[name];

    // Compute offsets and adjustments
    const xOffset = width / 2 - 50; // Adjust X to center the foreignObject
    const yOffset = value === 0 ? 36 : 28; // Adjust Y if value is 0
    const adjustedY = y - yOffset - 10; // Move up by height of the bar + additional offset

    return (
      <g transform={`translate(${x + xOffset}, ${adjustedY})`}>
        <foreignObject width={100} height={100} style={{ overflow: "visible" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            {logo && (
              <img
                src={logo}
                alt={name}
                style={{ width: "1em", height: "1em", borderRadius: "50%" }}
              />
            )}
            <span
              style={{
                fontSize: "0.625em",
                color: "white",
                marginTop: logo ? "0.5em" : "0",
              }}
            >
              {(value * 100).toFixed(2)} ¢
            </span>
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <div className="bar-chart-container">
      <p className="title">{title}</p>
      {/* This is inconsistent and not dynamic! */}
      <ResponsiveContainer>
        <BarChart data={data}>
          <defs>
            {data.map((entry, index) => (
              <>
                <linearGradient
                  id={`gradient${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="0%"
                    stopColor={index === 0 ? "#82ca9d" : "#EA4646"}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor={index === 0 ? "#82ca9d" : "#EA4646"}
                    stopOpacity={index === 0 ? 0.5 : 0.25}
                  />
                </linearGradient>
                <linearGradient
                  id={`gradient-hover-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor={index === 0 ? "#82EA9d" : "#FF6868"} stopOpacity={1} />
                  <stop offset="100%" stopColor={index === 0 ? "#82EA9d" : "#FF6868"} stopOpacity={0.25} />
                </linearGradient>
              </>
            ))}
          </defs>
          <XAxis
            dataKey="name"
            tick={{
              dy: 10,
              fill: "#FFFFFF", // Sets the text color
            }}
            // angle={-15} // Angle the labels
            style={{ fontSize: "0.625em", color: "#FFFFFF" }}
          />
          <YAxis tick={false} domain={[0, "dataMax + 0.1"]}>
            <Label
              value="Amount per dollar staked"
              angle={-90}
              style={{ fontSize: "0.625em" }}
              fill="#FFF"
            />
          </YAxis>
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Bar dataKey="security_cost" fill="#FFF3B3" shape={<CustomBar />}>
            {data.map((entry, index) => (
              <Cell
                key={index}
                // fill={index === 0 ? `url(#gradient-hover-${index})` : `(#gradient-hover-${index})`}
                fill={`url(#gradient-hover-${index})`}
                
                radius={8}
                style={{
                  height: 8
                }}
              />
            ))}
            <LabelList
              // dataKey="logo"
              content={renderCustomLabel}
              // dataKey={(value) => `$${value.security_cost}`}
              // position="top"
              // style={{
              //   fontSize: "0.625em",
              // }}
              // fill="#959595"
            />
          </Bar>
          <Legend
            content={BarChartCommon.customLegend}
            align="center"
            verticalAlign="top"
            wrapperStyle={{
              // right: 0,
              left: "1em",
              transform: "translate(0, -1.5em)",
            }}
          />
          {/* <Bar dataKey="security_cost_eigenlayer" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
      {fetchError && <p style={{ color: "red" }}>{fetchError}</p>}
    </div>
  );
};

export default CandidateBarChart;
