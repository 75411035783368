import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import supabase from "../../config/supabaseClient";
import LRTChartCommon from "./lrtChartCommon";

const Chart = ({ title }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch the last 100 rows
      let { data, error } = await supabase
        .from("lrt_growth_chart")
        .select("block_number, token_symbol, total_supply")
        .order("block_number", { ascending: true })
        // .limit(404);

      if (error) {
        console.error("Error fetching data:", error);
        return;
      }

      const processedData = processData(data);
      setChartData(processedData);
    };

    fetchData();
  }, []);

  const processData = (data) => {
    // First, create a Set of all unique block numbers and tokens
    const allBlockNumbers = new Set(data.map(item => item.block_number));
    const allTokens = new Set(data.map(item => item.token_symbol));
    
    // To hold all of the processed data for each block
    const processedData = {};

    allBlockNumbers.forEach(block => {
      processedData[block] = {};
      // Initialize all changes values to zero
      allTokens.forEach(token => {
        processedData[block][token] = 0;
      })
    })
    
    // The initial values from 7 days ago to plot the change from
    const initialValues = {};

    data.forEach(({block_number, token_symbol, total_supply}) => {
      // Initialize the value if it isn't there yet
      if (! initialValues[token_symbol]) {
        initialValues[token_symbol] = total_supply;
      }

      // Get the change from the previous block number
      processedData[block_number][token_symbol] = total_supply - initialValues[token_symbol];
    })

    // Convert processedData into an array suitable for Recharts
    const chartData = Object.keys(processedData).map(block_number => {
      return { ...processedData[block_number] };
    }) 

    return chartData;
  
  };

  // Get nameMap and colors from common source
  const nameMap = LRTChartCommon.nameMap;
  const chartColors = LRTChartCommon.colors;
  const colorMap = LRTChartCommon.colorMap;

  // Sort tokens alphabetically
  const uniqueTokensSorted = Object.keys(nameMap).sort((a, b) => {
    const nameA = nameMap[a], nameB = nameMap[b];
    return nameA.localeCompare(nameB);
  });

  // Render lines for each token
  const renderLines = uniqueTokensSorted.map((token, index) => {
    return <Line
      key={token}
      type="monotone"
      dataKey={token}
      name={nameMap[token] || token}
      stroke={chartColors[index % chartColors.length]}
      dot={false}
    />
  });

  // Customized so that we can ignore 0-entries
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      
      // Filter out entries with zero values
      // const validPayload = payload.filter(entry => entry.value > 0);
      // if (validPayload.length === 0) return null;
      const sortedPayload = [...payload].sort((a, b) => {
        return b.value - a.value;
      })
      console.log("sorted", sortedPayload, "normal", payload)
  
      return (
        <div className="custom-tooltip" style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.75em",
          padding: "1em 5px",
          fontSize: "10px",
          backgroundColor: "rgba(0, 0, 0)",
          color: "white",

        }}>
          {sortedPayload.map((entry, index) => {
            return <p 
              style={{
                color: colorMap[entry.name],
              }}
              key={`item-${index}`}>
              {`${nameMap[entry.name] || entry.name} : ${new Intl.NumberFormat().format(Math.round(entry.value))} ETH`}
            </p>
          })}
        </div>
      );
    }
  };

  return (
    <div>
      <p
        style={{
          textAlign: "left",
          color: "white",
          marginBottom: "2vh",
          fontSize: "14px",
        }}
      >
        {title}
      </p>
      <ResponsiveContainer height={200} width="100%">
        <LineChart data={chartData}>
          <CartesianGrid stroke="#2e2e2e" vertical={false} />
          <XAxis dataKey="someKey" />
          <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
          <Tooltip content={<CustomTooltip />}></Tooltip>
          <Legend
            iconType="circle"
            iconSize="8"
            wrapperStyle={{ fontSize: "9px" }}
          />
          {renderLines}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
