import React, { useEffect, useState } from "react";
// import Grid from "@mui/material/Grid";
import supabase from "../../config/supabaseClient";
// import AlphaItem from "./alphaComponents/alphaItem";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

import "./alpha.scss";
import "../LrtTable/lrtTable.scss";
import clsx from "clsx";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const Alpha = () => {
  const [expandedRow, setExpandedRow] = useState(null); // Track expanded row
  const [openDialog, setOpenDialog] = useState(false); // State to handle dialog visibility
  const [selectedRow, setSelectedRow] = useState(null); // State to store selected row data
  const [alphas, setAlphas] = useState();

  useEffect(() => {
    const fetchAlpha = async () => {
      const { data, error } = await supabase
        // TODO: Change this to alpha when we have that table
        .from("alpha") // The table we are pulling from
        .select(); // No arguments gets the whole table

      // if (error) {
      //   setFetchError("Could not fetch the selected table");
      //   console.log(error);
      // }

      if (data) {
        const transformedData = data.map((item, index) => ({
          id: index + 1, // Adding a unique id for each row
          strategy: item.strategy,
          apy: item.apy,
          token: item.token,
          category: item.category,
          logo: item.logo, // Ensure this field is included in your data
          link: item.link,
        }));

        transformedData.sort((a, b) => b.apy_float - a.apy_float);
        setAlphas(transformedData);
        // setFetchError(null);
      }
    };

    fetchAlpha();
  }, []);

  const columns = [
    // { field: "id", headerName: "#", width: 10 },
    {
      field: "strategy",
      headerName: "Strategy",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginRight: "2em",
          }}
        >
          <img
            src={params.row.logo}
            alt=""
            style={{ width: 20, height: 20, borderRadius: 300 }}
          />
          {params.value}
        </Box>
      ),
    },
    { field: "token", headerName: "Token", flex: 1 },
    {
      field: "category",
      headerName: "Strategy Type",
      flex: 1,
    },
    { 
      field: "apy", 
      headerName: "APY", 
      flex: 1,
      valueFormatter: (params) => {
        return params.value + " %";
      } },
   
  ];

  const dataGridStyles = {
    border: 0,
    fontFamily: "IBM Plex Mono, monospace",
    "& .MuiDataGrid-cell": {
      color: "#B0B0B0", // Adjust if necessary to change cell text color
      border: "none",
    },
    "& .MuiDataGrid-columnHeader": {
      color: "white", // Change column header text to white
      border: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: "#888890", // Ensures the text color of the header titles is white
    },
    "& .MuiDataGrid-columnHeaders": {
      color: "#888890", // Additional targeting for all header elements
      border: "none",
    },
    "& .MuiDataGrid-sortIcon": {
      color: "white", // Specifically targets the sort icons
    },
    "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
      color: "white", // Targets all SVG icons in the column headers, including sort icons
    },
    "& .MuiDataGrid-row": {
      backgroundColor: "#242424",
      marginBottom: "10px",
      borderRadius: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "translateY(-3px)",
        backgroundColor: "#242424",
        cursor: "pointer",
      },
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      border: "none",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
      visibility: "hidden", // Hides the column separators
    },

    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },

    "& .MuiDataGrid-columnHeader:focus-within": {
      outline: "none !important",
    },
  };

  // Function to open dialog and set selected row
  const handleRowClick = (params) => {
    const url = params.row.link; // Get the link from the row data
    if (url) window.open(url, "_blank"); // Open in a new tab if the URL exists
  };

  //   // Function to close dialog
  //   const handleCloseDialog = () => {
  //     setOpenDialog(false);
  //     setSelectedRow(null); // Optionally reset selected row data
  //   };

  return alphas ? (
    <Box
      sx={{
        height: "52.5vh",
        paddingRight: "25px",
        paddingLeft: "25px",
        overflow: "auto",
      }}
    >
      <DataGrid
        rows={alphas}
        columns={columns}
        sx={{
          ...dataGridStyles,
          minWidth: 1000,
        }}
        hideFooter={"true"}
        initialState={{
          pagination: {},
        }}
        rowsPerPageOptions={[5]} // Corrected property name
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        onRowClick={handleRowClick} // Add the onRowClick prop here
      />
    </Box>
  ) : (
    ""
  );
  // <div className="table-container" style={{ marginBottom: "10px" }}>
  //   <table className="alpha-table" cellPadding={0} cellSpacing={0}>
  //     <thead>
  //       <tr className="header-row">
  //         <th>Strategy</th>
  //         <th>APY</th>
  //         <th>Token</th>
  //         <th>Risk</th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {alphas
  //         ? alphas.map((alpha, index) => (
  //             <React.Fragment key={index}>
  //               <tr
  //                 className="body-row"
  //                 onClick={() => handleRowClick(alpha.link)}
  //               >
  //                 <td style={{ borderRadius: "10px 0px 0px 10px" }}>
  //                   <div className="contains-image">
  //                     <img className="logo" src={alpha.logo}></img>
  //                     <span>    {alpha.strategy}</span>
  //                   </div>

  //                 </td>
  //                 <td>{alpha.apy}</td>
  //                 <td>{alpha.token}</td>
  //                 <td style={{ borderRadius: "0px 10px 10px 0px" }}>
  //                   {alpha.risk}
  //                 </td>
  //                 <td></td>
  //               </tr>
  //             </React.Fragment>
  //           ))
  //         : ""}
  //     </tbody>
  //   </table>

  {
    /* <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "#1C1C28",
            // border: "solid 1px #27324D",
            borderRadius: "10px",
            // fontFamily: '"DM Sans", sans-serif',
            color: "white",
          },
        }}
      >

        
        <DialogTitle>{"Strategy Details"}</DialogTitle>
        <DialogContent>
        <DialogContentText style={{ color: "#BBBBBB" }}>
            {selectedRow && (
              <>
                <div style={{ marginBottom: "5px" }}>Strategy: {selectedRow.strategy}</div>
                <div style={{ marginBottom: "5px" }}>APY: {selectedRow.apy}</div>
                <div style={{ marginBottom: "5px" }}>Token: {selectedRow.token}</div>
                <div style={{ marginBottom: "5px" }}>Risk: {selectedRow.risk}</div>
                <div style={{ marginBottom: "5px" }}>Description: {selectedRow.description}</div>
                <div style={{ marginBottom: "5px" }}>Construction: {selectedRow.construction}</div>
                <div style={{ marginBottom: "5px" }}>Risks: {selectedRow.risks}</div>

              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog> */
  }
  // </div>
  // );
};

//               </>
//             )}
//           </DialogContentText>
//         </DialogContent>
//       </Dialog>
//     </div>
//   );
// };

export default Alpha;
