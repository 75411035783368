import { createClient } from "@supabase/supabase-js";

const tempSupabaseUrl = "https://pprahgrnhfhccbcporfc.supabase.co";

const tempSupabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBwcmFoZ3JuaGZoY2NiY3BvcmZjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTExNjU0NzgsImV4cCI6MjAyNjc0MTQ3OH0.H05xvlECWxLO9giz_uimhjovkE3RD3Tdo9-f5P6P1qU";

// const supabase = createClient(tempSupabaseUrl, tempSupabaseKey);

const realSupabaseUrl = "https://grktnugqtnlnljaeyfnl.supabase.co";

const realSupabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imdya3RudWdxdG5sbmxqYWV5Zm5sIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTIxMDk5NzYsImV4cCI6MjAyNzY4NTk3Nn0.CWHo-X6Sw2zfOwdNKoUErF6AlSFIyzgGYzVPevx-tMQ"

const supabase = createClient(realSupabaseUrl, realSupabaseKey);

export default supabase;


