import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import supabase from "../../../config/supabaseClient";
import "./AVSOverviewHeaderGrid.scss";

export default function AVSOverviewHeaderGrid({data}) {
  // Placeholders
  const avs_at_risk = 0;
  const value_at_risk = "$425.6M";
  const most_secure_avs = {
    // name: avsAttackData ? avsAttackData[0].protocol_name : "null",
    // logo: avsAttackData ? avsAttackData[0].protocol_logo : ""

    name: "Coming soon",
    logo: "",
  };


  return (
      <div className="header-grid">
        <Box className="grid-item">
          <span>{data.title1}</span>
          <h2>{data.info1} 
          <Box
              sx={{
                width: 8,
                height: 8,
                backgroundColor: "#19E590",
                borderRadius: "50%",
              }}
            /></h2>
        </Box>
        <Box className="grid-item">
          <span>{data.title2}</span>
          <h2>{data.info2}
          </h2>
        </Box>
        <Box className="grid-item">
          <span>{data.title3}</span>
          <div className="logo-entry">
            {/* <img src={most_secure_avs.logo} alt=""></img> */}
            <h2>{data.info3}</h2>
          </div>
        </Box>
      </div>
  )
}
