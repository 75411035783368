import React, { useState } from "react";
import Chart from "./Chart/Chart.jsx";
import BarChart from "./Chart/BarChart.jsx";
import MarketChart from "./Chart/MarketChart.jsx";
import Header from "./Header/Header.jsx";
import LrtTable from "./LrtTable/LrtTable.jsx";
import Points from "./Points/Points.jsx";
import Alpha from "./Alpha/Alpha.jsx";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./lrtpage.scss";
import Grid from "@mui/material/Grid";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, MenuItem } from "@mui/material";

const LrtPage = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("LRTs");

  // Handle changing of the selected menu item
  const handleTableDropdown = (event) => {
    setSelectedMenuItem(event.target.value);
  };

  // Dynamically render component based on selected menu item
  const renderComponent = () => {
    switch (selectedMenuItem) {
      case "LRTs":
        return <LrtTable />;
      // case "Points":
      //   return <Points />;
      case "Alpha":
        return <Alpha />;
      default:
        return <LrtTable />; // Default to LrtTable or any component you prefer
    }
  };

  return (
    <div className="dashboard">
      <Grid container className="chart-grid" spacing={2}>
        <Grid item xs={12} lg={3}>
          <Box className="grid-item" 
            sx={{
              borderRadius: "0.5em"
            }}
          >
            <MarketChart title="LRT Market Share" />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4.5}>
          <Box className="grid-item" 
            sx={{
              borderRadius: "0.5em"
            }}
          >
            <BarChart className="chart" title="LRT Supply Over Time" />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4.5}>
          <Box className="grid-item" 
            sx={{
              borderRadius: "0.5em"
            }}
          >
            <Chart className="chart" title="LRT Growth (Last 7D)" />
          </Box>
        </Grid>
      </Grid>
      <div className="adv-terminal">
        <div className="terminal-header">
          <h2>Advanced Terminal</h2>
          {/* <Box> */}
          <Select
            labelId="select-table-triangle"
            id="select-table-dropdown"
            value={selectedMenuItem}
            onChange={handleTableDropdown}
            sx={{
              height: "40px",
              marginRight: "2.5%",
              borderRadius: "10px",
              fontFamily: "IBM Plex Mono, monospace",
              fontSize: "14px",
              color: "black",
              // borderRadius: "5px",
              backgroundColor: "#FDEFD3", // Text color
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FDEFD3", // Border color
                borderWidth: "0.00px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FDEFD3", // Hover state border color
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FDEFD3", // Focus state border color
                borderWidth: "0px",
              },
              "& .MuiSvgIcon-root": {
                color: "black", // Dropdown icon color
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "#44423D", // Change dropdown background color here
                  boxShadow: "none",
                  // border: ".5px solid #FDEFD3",
                  borderRadius: "10px",
                  fontFamily: "IBM Plex Mono, monospace",
                  // You can also target other dropdown menu styles here
                },
              },
            }}
          >
            <MenuItem
              value="LRTs"
              sx={{ fontFamily: "IBM Plex Mono, monospace", color: "#FDEFD3" }}
            >
              LRTs
            </MenuItem>

            <MenuItem
              value="Alpha"
              sx={{ fontFamily: "IBM Plex Mono, monospace", color: "#FDEFD3" }}
            >
              Alpha
            </MenuItem>

            {/* NOTE: Uncomment to re-add points simulator */}
            {/* <MenuItem
              value="Points"
              sx={{ fontFamily: "IBM Plex Mono, monospace", color: "#FDEFD3" }}
            >
              Points Simulator
            </MenuItem> */}
          </Select>
        </div>
        {renderComponent()}
      </div>
    </div>
  );
};

export default LrtPage;
