import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import supabase from "../../../../config/supabaseClient";
import tableStyles from "./TableStyles";
import { render } from "@testing-library/react";

export default function AVSCandidateTable() {
  const [avsCandidateData, setAvsCandidateData] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchBlock = async () => {
      const { data, error } = await supabase
        .from("avs_candidates") // The table we are pulling from
        .select(); // Fetches all columns by default, ensure 'protocol_logo' is included

      if (error) {
        setFetchError("Could not fetch the token table");
        console.log(error);
      }

      if (data) {
        // Transforming the block data to match DataGrid rows structure
        const transformedData = data.map((item, index) => ({
          id: index + 1, // Adding a unique id for each row
          name: item.name,
          logo: item.logo,
          category: item.category,
          cost_of_corruption: item.cost_of_corruption,
          earnings_from_corruption: item.earnings_from_corruption,
          security_cost: item.security_cost,
          security_cost_eigenlayer: item.security_cost_eigenlayer,
          pos_tvl: item.pos_tvl,
          token_volatility: item.token_volatility,
          eth_volatility: item.eth_volatility,
          // cost_to_attack: item.cost_to_attack,
          // potential_profit: item.potential_profit,
          // cost_to_profit_ratio: item.cost_to_attack / item.potential_profit,
        }));

        // Sort the transformedData array by the total_supply column
        setAvsCandidateData(transformedData);
      }
    };

    fetchBlock();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "AVS Name",
      minWidth: tableStyles.calculateMinWidth("AVS Name"),
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <img
            src={params.row.logo}
            alt=""
            style={{ width: 20, height: 20, borderRadius: 300 }}
          />
          {params.value}
        </Box>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Category"),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: tableStyles.getCategoryTextColor(params.value),
            backgroundColor: tableStyles.getCategoryBackgroundColor(
              params.value
            ),
            borderRadius: "0.5em",
            padding: "0.25em 0.5em",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "security_cost",
      headerName: "Security Cost",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Security Cost per dollar staked"),
      valueFormatter: (params) => {
        if (params.value === null) {
          return "Coming soon";
        } else {
          return `$${new Intl.NumberFormat().format(params.value)} per dollar staked`;
        }
      },
    },
    {
      field: "security_cost_eigenlayer",
      headerName: "Security Cost w/ Eigenlayer",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Security cost w/ Eigenlayer"),
      valueFormatter: (params) => {
        if (params.value === null) {
          return "Coming soon";
        } else {
          if (params.value === 0) {
            return "Free"
          } else {
            return `$${new Intl.NumberFormat().format(params.value)} per dollar staked`;
          }
        }
      },
      renderCell: (params) => {
        let render_value = "";
        if (params.value === null) {
          render_value = "Coming Soon"
        } else if (isNaN(params.value)){
          render_value = "-"
        } else if (params.value === 0){
          render_value = "Free"
        } else {
          render_value = `${new Intl.NumberFormat().format(params.value)} per dollar staked`
        }
        return <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              color: (render_value === "-" || params.value === "Coming soon") ? "#B0B0B0" : "#19E590",
              fontWeight: 500,
            }}
          >
            {render_value}
          </Box>
        </Box>
      }
    },
    {
      field: "token_volatility",
      headerName: "Price Volatility of Security",
      flex: 1,
      minWidth: tableStyles.calculateMinWidth("Price Volatility of Security"),
      // valueFormatter: (params) => {
      //   if (params.value === null) {
      //     return "Coming soon";
      //   } else {
      //     return `${new Intl.NumberFormat().format(params.value)}%`;
      //   }
      // },
      renderCell: (params) => {
        const render_value = (params.value === null) ? "Coming Soon" : (isNaN(params.value) ? "-" : `${new Intl.NumberFormat().format(params.value)} %`)
        return <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              color: (render_value === "-" || params.value === "Coming soon") ? "#B0B0B0" : "#FF4C52",
              fontWeight: 500,
            }}
          >
            {render_value}
          </Box>
        </Box>
      }
    },
    {
      field: "eth_volatility",
      headerName: "Price Volatility of Security w/ Eigenlayer",
      minWidth: tableStyles.calculateMinWidth("Price Volatility of Security w/ Eigenlayer"),
      flex: 1,
      
      // valueFormatter: (params) => {
      //   if (params.value === null) {
      //     return "Coming soon";
      //   } else {
      //     return `${new Intl.NumberFormat().format(params.value)}%`;
      //   }
      // },
      renderCell: (params) => {
        const render_value = (params.value === null) ? "Coming Soon" : (isNaN(params.value) ? "-" : `${new Intl.NumberFormat().format(params.value)} %`)
        return <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              color: (render_value === "-" || params.value === "Coming soon") ? "#B0B0B0" : "#19E590",
              fontWeight: 500,
            }}
          >
            {render_value}
          </Box>
        </Box>
      }
    },
  ];

  return avsCandidateData ? (
    <Box
      sx={{
        height: "380px",
        // overflow: "auto",
        // border: "1px solid #2E2E2E",
        // borderRadius: "1em",
      }}
    >
      <DataGrid
        rows={avsCandidateData}
        columns={columns}
        sx={{
          ...tableStyles.dataGridStyles,
          minWidth: 1000,
        }}
        hideFooter={"true"}
        initialState={{
          pagination: {},
        }}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        // onRowClick={handleRowClick} // Add the onRowClick prop here
      />
    </Box>
  ) : (
    ""
  );
}
