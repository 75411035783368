import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import "./mobilemenu.scss";
import Grow from "@mui/material/Grow"; // Import Grow transition

import Argo_Logo from "../assets/Argo_Logo.svg";
import Navbar from "./Navbar";

const MobileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="mobile-menu">
      <img src={Argo_Logo} alt="Resteak Logo" />

      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="large"
        sx={{ color: "white" }}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        className="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow} // Apply the Grow transition

        PaperProps={{
          style: {
            width: "100%", // Cover the full viewport width
            maxHeight: "100vh", // Limit to viewport height, making it full screen
            backgroundColor: "#1C1C28",
            boxShadow: "none",
            fontFamily: "IBM Plex Mono, monospace",
            color: "white",
          },
        }}
      >
        <Navbar />
      </Menu>
    </div>
  );
};

export default MobileMenu;
